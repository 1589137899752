<template>
    <v-container>
        <div class="token">
            <v-alert :type="hints.type" :value="hints.alert" transition="scale-transition">{{hints.message}}</v-alert>
            <div class="inner">
                <v-textarea outlined label="API Token" :value="apiToken" no-resize readonly></v-textarea>

                <v-btn @click="getAPIToken" class="btn" large outlined
                    >获取API Token</v-btn
                >
                <v-btn @click="copyAPIToken" class="btn" large outlined
                    >复制API Token</v-btn
                >
            </div>
        </div>
    </v-container>
</template>

<script>
import axios from "axios";
import event from "../components/eventBus/index";

export default {
    name: "home",
    data() {
        return {
            hints: {
                type: "success",
                alert: false,
                message: ""
            },
            apiToken: ""
        };
    },
    methods: {
        getAPIToken() {
            let vue = this;
            let token = localStorage.getItem("token");
            axios({
                method: "get",
                url: "api/frontend/v1/user/api_token",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    this.hints.type = "success";
                    vue.hints.message = res.data.message;
                    vue.hints.alert = true;

                    vue.apiToken = res.data.data.api_token;
                })
                .catch((e) => {
                    console.log(e);
                    let res = e.response;
                    if (typeof res != "undefined") {
                        if (!res.data.success) {
                            vue.hints.type = "error";
                            vue.hints.message = res.data.message;
                            vue.hints.alert = true;
                        }
                    }
                });
        },
        copyAPIToken() {
            if (this.apiToken == "") {
                this.hints.type = "error";
                this.hints.message = "请先获取API Token";
                this.hints.alert = true;
            } else {
                this.$copyText(this.apiToken).then(data => {
                    this.hints.type = "success";
                    this.hints.message = "API Token复制成功";
                    this.hints.alert = true;
                }, e => {
                    this.hints.type = "error";
                    this.hints.message = "API Token复制失败";
                    this.hints.alert = true;
                });
            }
        },
    },
    mounted() {
        let token = localStorage.getItem("token");
        let vue = this;
        if (token != null) {
            axios({
                method: "get",
                url: "api/frontend/v1/user/info",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.data.success) {
                        event.$emit("loginSuccess", res.data);
                    }
                })
                .catch((e) => {
                    let res = e.response;
                    if (typeof res != "undefined") {
                        if (!res.data.success) {
                            localStorage.removeItem("token");
                            vue.$router.push("/");
                        }
                    }
                });
        } else {
            vue.$router.push("/");
        }
    },
};
</script>

<style>
.token {
    width: 450px;
    max-width: calc(100% - 20px);
    padding: 8% 0 0;
    margin: auto;
}

.inner {
    background-color: #fafafa;
    border: none;
    border-radius: 0.25rem;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
    padding: 40px 40px;
    margin-top: 20px;
    margin-left: 0;
    font-weight: 400;
    overflow: hidden;
    background: #fff;
}

.btn {
    float: none;
    display: block !important;
    margin: auto;
    margin-bottom: 8px;
}
</style>
