<template>
    <v-app>
        <v-main>
            <v-toolbar>
                <v-app-bar-nav-icon :disabled="drawer.navIcon" @click="drawer.enable=!drawer.enable"></v-app-bar-nav-icon>

                <v-toolbar-title>MineBBS Developer API</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn text :href="user.page" :disabled="user.btn">
                    <v-avatar tile size="24">
                        <img :src="user.avatar">
                    </v-avatar>

                    <div class="username">{{user.name}}</div>
                </v-btn>
            </v-toolbar>

            <v-navigation-drawer v-model="drawer.enable" absolute temporary>
                <v-list flat subheader>
                    <v-subheader> API </v-subheader>

                    <v-list-item-group v-model="drawer.api.item">
                        <v-list-item v-for="(item, i) in drawer.api.items" :key="i">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <v-divider></v-divider>

                <v-list flat subheader>
                    <v-subheader> DOCS </v-subheader>

                    <v-list-item-group v-model="drawer.docs.item">
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>

            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import event from './components/eventBus/index';
import favicon from "./assets/favicon.png"

export default {
    name: "App",
    data() {
        return {
            user: {
                name: "",
                page: "",
                avatar: favicon,
                btn: true
            },
            drawer: {
                navIcon: true,
                enable: false,
                api: {
                    item: 1,
                    items: [
                        { icon: "mdi-api", text: "API KEY 获取" }
                    ]
                },
                docs: {
                    item: 1
                }
            }
        };
    },
    mounted() {
        event.$on("loginSuccess", e => {
            let data = e.data;
            let avatar = data.avatar;
            if (data.avatar == "") {
                avatar = "https://api.minebbs.com/assets/images/DefaultAvatars.png";
            }
            this.user.avatar = avatar;
            
            this.user.name = data.user_name;
            this.user.page = `https://www.minebbs.com/members/${data.user_id}`;
            this.user.btn = false;

            this.drawer.navIcon = false;
        });
    }
};
</script>

<style>
.username {
    padding-left: 8px;
}
</style>
