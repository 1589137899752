<template>
    <v-container>
        <div class="login">
            <v-alert type="error" :value="error.alert" transition="scale-transition">{{error.message}}</v-alert>
            <v-form v-model="valid" class="login_form" id="login_form" ref="login">
                <p class="login_title">登录</p>
                <v-text-field
                    v-model="login.username"
                    label="用户名/邮箱"
                    :rules="nameRules"
                    outlined
                    required
                ></v-text-field>

                <v-text-field
                    v-model="login.password"
                    label="密码"
                    :rules="passwordRules"
                    type="password"
                    outlined
                    required
                ></v-text-field>

                <div id="vaptchaContainer" class="vaptchaContainer"></div>

                <v-btn @click="validate" class="submit_btn" large outlined>登录</v-btn>
            </v-form>
        </div>
    </v-container>
</template>

<style>
.login {
    width: 400px;
    max-width: calc(100% - 20px);
    padding: 8% 0 0;
    margin: auto;
}

.login_title {
    padding-bottom: 10px;
}

.login_form {
    background-color: #fafafa;
    border: none;
    border-radius: 0.25rem;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
    padding: 40px 40px;
    margin-top: 20px;
    margin-left: 0;
    font-weight: 400;
    overflow: hidden;
    background: #fff;
}

.submit_btn {
    float: none;
    display: block !important;
    margin: auto;
}

.vaptchaContainer {
    padding-bottom: 10px;
}
</style>

<script>
import axios from "axios";
import { vaptcha } from "@/extends/v3";

export default {
    name: "login",
    data() {
        return {
            valid: false,
            nameRules: [(v) => !!v || "用户名字段为空!"],
            passwordRules: [(v) => !!v || "密码字段为空!"],
            login: {
                username: "",
                password: "",
            },
            error: {
                message: "",
                alert: false,
            },
            vaptcha: {
                object: "",
            },
        };
    },
    methods: {
        validate() {
            let isValidate = this.$refs.login.validate();
            if (isValidate) {
                let vaptchaObject = this.vaptcha.object;
                let vaptchaToken = vaptchaObject.getServerToken().token;
                let vaptchaServer = vaptchaObject.getServerToken().server;
                console.log(vaptchaServer);
                if (vaptchaToken.length != 0 && vaptchaServer.length != 0) {
                    let vue = this;

                    axios({
                        method: "post",
                        url: "api/frontend/v1/auth/login",
                        data: {
                            account: this.login.username,
                            password: this.login.password,
                            captcha: {
                                server: vaptchaServer,
                                scene: 1,
                                token: vaptchaToken,
                            },
                        },
                    })
                        .then((response) => {
                            let data = response.data;
                            if (data.success) {
                                localStorage.setItem("token", data.data.token);
                                vue.$router.push("/Home");
                            }
                        })
                        .catch((e) => {
                            let response = e.response;
                            vue.error.message = response.data.message;
                            vue.error.alert = true;
                            vue.$refs.login.reset();
                            vue.vaptcha.object.reset();
                        });
                }
            }
        },
    },
    mounted() {
        let token = localStorage.getItem("token");
        let vue = this;
        if (token != null) {
            this.$router.push("/home");
        } else {
            vaptcha().then((vaptcha) => {
                vaptcha({
                    vid: "61e2be220a949a41022707a5",
                    mode: "click",
                    scene: 1,
                    container: "#vaptchaContainer",
                }).then(function (vaptchaObj) {
                    vaptchaObj.renderTokenInput();
                    vaptchaObj.render();

                    vue.vaptcha.object = vaptchaObj;
                });
            });
        }
    },
};
</script>
